import styled from 'styled-components';

export const Price = styled.div`
  color: #e26447;
  font-size: 18px;
  display: block;
  white-space: nowrap;

  ${({ pageDesign }) => {
    switch (pageDesign) {
      case 'design2':
        return `
                    color: #001C72;
                    font-size: 20px;

                    @media (max-width: 500px) {
                        font-size: 24px;
                    }
                `;
      default:
        return ``;
    }
  }}

  &.column-price {
    display: flex;
    flex-direction: column;
    justify-content: center;

    & > span {
      @media (max-width: 500px) {
        font-size: 14px;
        line-height: 17px;
      }
    }

    @media (max-width: 500px) {
      font-size: 20px;
      line-height: 24px;
    }
  }

  & > span {
    color: #213f4e;
    text-decoration: line-through;

    ${({ pageDesign }) => {
      switch (pageDesign) {
        case 'design2':
          return `
                        color: #99A4C7;
                        font-size: 14px;
                        @media (max-width: 500px) {
                            font-size: 16px;
                        }
                    `;
        default:
          return ``;
      }
    }}
  }

  & > p.custom-text {
    font-size: 16px;
    display: inline;
  }
`;
export const RebuildPrice = styled.p`
  margin: 8px 0 0 0;
  padding: 0;
  font-size: 16px;
`;
