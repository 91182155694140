import { ProductPrice } from '../../../utils/productPrice';
import * as S from './PriceComparison.styles';
import { formatPrice } from '../../../utils/formatPrice';
import React, { useContext } from 'react';
import FunnelContext from '../../../context/FunnelContext';

export const PriceComparison = props => {
  const {
    showPriceComparison,
    invertPriceComparison,
    priceSettings,
    addPerMonthTextOnSubPrices,
    showPriceComparisonColumn,
    pricesAfterRebuild,
    showPriceComparisonRebill,
    addCustomTextOnSubPrices = ''
  } = props;

  const {
    currentCategory,
    extraObjects,
    currentTubIndex,
    currentProduct,
    pageDesign
  } = useContext(FunnelContext);

  const discountedPrice = ProductPrice({
    type: `discounted_price`,
    currentCategory: currentCategory,
    currentProduct: currentProduct,
    filterByTag: extraObjects?.filterProductItemsByTag || false
  });
  const price = ProductPrice({
    type: `price`,
    currentCategory: currentCategory,
    currentProduct: currentProduct,
    filterByTag: extraObjects?.filterProductItemsByTag || false
  });
  if (showPriceComparison) {
    return (
      <S.Price
        className={`${
          showPriceComparisonColumn ? `column-price` : `price-comparison`
        }`}
        pageDesign={pageDesign}
      >
        {!invertPriceComparison && (
          <>
            {formatPrice(discountedPrice, priceSettings)}
            {addPerMonthTextOnSubPrices &&
            currentCategory === 'subscriptions' ? (
              addCustomTextOnSubPrices === '' ? (
                `/month`
              ) : (
                <p className="custom-text">{addCustomTextOnSubPrices}</p>
              )
            ) : (
              ``
            )}
          </>
        )}
        {discountedPrice === price ? (
          ``
        ) : (
          <span>{formatPrice(price, priceSettings)}</span>
        )}{' '}
        {invertPriceComparison && (
          <>
            {formatPrice(discountedPrice, priceSettings)}
            {addPerMonthTextOnSubPrices &&
            currentCategory === 'subscriptions' ? (
              addCustomTextOnSubPrices === '' ? (
                `/month`
              ) : (
                <p className="custom-text">{addCustomTextOnSubPrices}</p>
              )
            ) : (
              ``
            )}
          </>
        )}
        <S.RebuildPrice>
          {currentCategory === 'onetime' &&
            showPriceComparisonRebill &&
            pricesAfterRebuild[currentTubIndex]?.valueOtp}
          {currentCategory === 'subscriptions' &&
            showPriceComparisonRebill &&
            pricesAfterRebuild[currentTubIndex]?.valueSub}
        </S.RebuildPrice>
      </S.Price>
    );
  } else {
    return ``;
  }
};

export default PriceComparison;
